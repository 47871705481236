<template>
    <div class="smartyMeter-container">
        <CSTabBar :tabbar="orderState" :checkedTab="checkedTabs" @changeTabBar="changeTabBar" class="tabBar" />
        <template v-if="checkedTabs == 2">
            <InstrumentManagement></InstrumentManagement>
        </template>
        <template v-if="checkedTabs == 1">
            <AutomaticMeterReading></AutomaticMeterReading>
        </template>
    </div>
</template>

<script>
    import CSTabBar from "@/components/common/CSTabBar";
    import AutomaticMeterReading from "@/views/SmartMeter/AutomaticMeterReading";
    import InstrumentManagement from "@/views/SmartMeter/InstrumentManagement";
    export default {
        name: "SmartMeters",
        data(){
            return{
                orderState:{
                    2:'手动抄表',
                    1:'自动抄表'
                },
                checkedTabs:1
            }
        },
        methods:{
            changeTabBar(index){
                this.checkedTabs = index;
            }
        },
        components:{
            CSTabBar,
            InstrumentManagement,
            AutomaticMeterReading
        }
    }
</script>

<style scoped>

</style>