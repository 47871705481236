<template>
    <div class="'AutomaticMeterReading-container">
        <CSTabBar :tabbar="orderState" :checkedTab="checkedTabs" @changeTabBar="changeTabBar" class="tabBar" />
        <template v-if="checkedTabs == 1">
            <AutomaticMeterWater></AutomaticMeterWater>
        </template>
        <template v-if="checkedTabs == 2">
            <AutomaticMeterElen></AutomaticMeterElen>
        </template>
    </div>
</template>

<script>
    import CSTabBar from "@/components/common/CSTabBar";
    import AutomaticMeterWater from "@/views/SmartMeter/AutomaticMeterWater";
    import AutomaticMeterElen from "@/views/SmartMeter/AutomaticMeterElen";
    export default {
        name: "AutomaticMeterReading",
        components:{
            CSTabBar,
            AutomaticMeterWater,
            AutomaticMeterElen
        },
        data(){
            return{
                orderState:{
                    1:'水表',
                    2:'电表'
                },
                checkedTabs:1,

            }
        },
        methods:{
            changeTabBar(index){
                this.checkedTabs = index;
            }
        }
    }
</script>

<style scoped>

</style>